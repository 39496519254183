import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class SectorsRepository extends BaseRepository {
    baseUrl = 'management/specifications/sectors';

    /**
     * Get list of sectors
     *
     * @param criteria
     * @return {*}
     */
    getSectors(criteria) {
        return this.httpClient.post(`${this.baseUrl}/get-sectors`, criteria);
    }

    /**
     * Get list of sectors based on the type
     *
     * @return {*}
     */
    byType(type) {
        if (type !== 'lab') {
            return this.inSitu();
        }

        return this.lab();
    }

    /**
     * Get list of lab sectors
     *
     * @return {*}
     */
    lab() {
        return this.httpClient.get(`${this.baseUrl}/lab`);
    }

    /**
     * Get list of in-situ sectors
     *
     * @return {*}
     */
    inSitu() {
        return this.httpClient.get(`${this.baseUrl}/in-situ`);
    }
}
